@import "../bourbon/bourbon";

$color: #FF6347; //Theme skin
$text-color: #888;
$main-color: #222;
$bg-light: #FAFAFA;

@mixin kerning($kerning) {
	letter-spacing: $kerning + px; }

body {
	color: $main-color;
	@include kerning(1); }

a {
	color: $color; }

p,
body .main-footer,
.s-about li {
	color: $text-color; }

.main-footer {
	@include kerning(0); }

.color-element {
	color: $color; }
.color-bg {
	background-color: $color; }
.color-border {
	border-color: $color; }

.bg-light {
	background: $bg-light; }
.bg-dark {
	background: $main-color;
	color: #fff;
	.s-desc {
		&::after {
			background: #fff; } }
	a {
		color: #fff; } }
.main-color-bg {
    background-color: $main-color; }

svg {
	path {
		fill: #fff; } }


.top-menu {
	a {
		@include kerning(4); }

	a:hover {
		color: $color;
		span {
			background: rgba(255, 255, 255, .05);
			display: block; } } }


.top-text {
	p {
		color: $bg-light;
		@include kerning(5); } }

.s-desc {
	@include kerning(5);
	&::after {
		background: $main-color; } }

section {
	h3 {
		@include kerning(3);
		color: $text-color; } }

.s-about {
	p {
		&:nth-child(even) {
			color: $color; } } }

.personal-header {
	font-size: 13px;
	text-align: left;
	@include kerning(2); }

.resume-container {
	h3 {
		color: $main-color;
		@include kerning(1);
		font-size: 16px;
		font-weight: 600;
		margin-top: 30px; }
	.left .resume-desc strong {
		margin-left: 10px; }


	.right .resume-desc strong {
		margin-left: 10px; } }

.resume-icon {
	font-size: 40px;
	margin-bottom: 35px; }

.resume-item {
	.year {
		background: $color; }
	.resume-desc {
		@include kerning(1); } }

.s-portfolio {
	li {
		color: $text-color;
		@include kerning(2);
		&.active {
			color: #fff;
			border-bottom: 1px solid $color; } } }

.portfolio-item .portfolio-item-content {
	a {
		border-color: $color;
		color: $color; } }


.main-footer a {
	color: $color; }

.contacts-icon,
.text-danger {
	color: $color; }

form.main-form {
	input,
	textarea {
		outline: none;
		@include kerning(2); }
	input:focus,
	textarea:focus {
		outline: none;
		border: 1px solid $color; }


	button {
		background: $color;
		@include kerning(2); } }

.form-group {
	@include kerning(2);
	color: $text-color; }
